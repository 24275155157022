<template lang="pug">
  create-portfolio-section-template(
    title="Asset Selection"
    :sectionIndex="sectionIndex"
  )
    v-row(v-if="loading" style="height: 30em")
      v-overlay(absolute color="primary")
        v-progress-circular(:size="50" color="primary" indeterminate)
    v-row.pb-4(v-else)
      v-col(cols="12")
        v-text-field(
          hide-details
          dense
          solo
          flat
          outlined
          v-model="search_"
          placeholder="EUR, USD, ETC..."
        )
          template(#append)
            search-icon(size="1.5x")
      v-col.d-flex.align-center.flex-wrap.py-md-0(cols="12")
        .pr-2.font-weight-medium.colors--color12.mt-2
          | INDEXES:
        label.cp.indexes.mx-1.px-2.py-2
          input(v-model="selectedGroup_" type="radio" value="all")
          | All
        label.cp.indexes.mx-1.px-2.py-2(v-for="item in groups")
          input(v-model="selectedGroup_" :value="item" type="radio")
          | {{item}}
      v-col.mt-3.py-0(cols="12" md="2")
        v-list.transparent.rounded-lg.pt-0(dense elevation="1")
          v-subheader.pl-5
            span.font-weight-medium.fs-14.colors--color12 CATEGORIES ({{categories.length}})
            div.ml-auto.d-flex.align-center.cp.error--text.fs-10(
              v-if="selectedCategory_"
              @click="selectedCategory_ = null"
            )

          div(style="max-height: 410px; overflow-y: auto; overflow-x: hidden; padding-right:20px")
            label.cp.indexes.mx-1.px-2.py-2.my-1
              input(
                v-model="selectedCategory_" type="radio"
                :value="null"
              )
              .text-truncate All
            label.cp.indexes.mx-1.px-2.py-2.my-1(v-for="(item, i) in categories")
              input(
                v-model="selectedCategory_" type="radio"
                :value="item.key"
                :key="item.key"
              )
              .text-truncate {{item.name.en}}
      v-col.mt-3.py-0.d-flex.flex-column.px-md-0(
        cols="12" md=""
      )
        v-list.transparent.rounded-lg.pt-0.list--border(dense elevation="1")
          v-subheader.pl-5
            span.font-weight-medium.fs-14.colors--color12 SEARCH RESULTS
              |  ({{tickersMeta.totalSize}})
            div.ml-auto.d-flex.align-center.cp.colors--color16.fs-10(
              v-if="filteredTickers.length && filteredTickers.length < 500"
              @click="selectAllTickers"
            )
              | Select all
              chevrons-right-icon.ml-1(size="1x")

          v-virtual-scroll(
            v-if="filteredTickers.length"
            :items="filteredTickers"
            item-height="40"
            height="410"
            bench="5"
            ref="tickerListRef"
          )
            template(v-slot="{ item }")
              v-list-item.cp(:ripple="true"
                @click="selectTicker(item)")
                v-list-item-icon.mr-4
                  plus-icon.colors--color16(size="1.5x")
                v-list-item-content
                  v-list-item-title.d-flex
                    span.mr-2.font-weight-regular.fs-14.colors--color16(
                      :v-tooltip="`[${item.code}] ${item.name}`"
                    )
                      | [{{item.code}}]
                    span.mr-2.text-truncate.font-weight-regular.fs-14.colors--color14 {{item.name}}
                    span.ml-auto.pl-3.font-weight-regular.fs-11.colors--color16(
                      :v-tooltip="`${item.price}`"
                    )
                      | {{getTickerPrice(item)}}
          .d-flex.flex-grow-1.animated.fadeIn.no-results(
            v-if="!filteredTickers.length"
            style="height: 408px"
          )
            .d-flex.align-center.justify-center.flex-column.ma-auto
              v-icon.grey--text.fs-24 fas fa-cubes
              .text-body.grey--text No results
      v-col.mt-3.py-0.d-flex.flex-column(
        cols="12" md=""
      )
        v-list.transparent.rounded-lg.pt-0.list--border(dense elevation="1")
          v-subheader.pl-5
            span.font-weight-medium.fs-14.colors--color12
              | SELECTION ({{formData.tickers.length}})
            span.error.white--text.px-2.mx-auto.d-flex.align-center(
              v-if="formData.tickers.length < 3"
            )
              alert-triangle-icon.mr-1(size="1.4x")
              | Select at least 3 assets
            div.ml-auto.d-flex.align-center.cp.error--text.fs-10(
              v-if="formData.tickers.length"
              @click="removeAllTickers"
            )
              | Remove all
              chevrons-left-icon.error--text.ml-1(size="1x")
          v-virtual-scroll(
            v-if="formData.tickers.length"
            :items="formData.tickers"
            item-height="40"
            height="410"
            bench="5"
          )
            template(v-slot="{ item }")
              v-list-item.cp(
                color="primary"
                :ripple="true"
                @click="removeTicker(item.symbol)"
                :input-value="true"
              )
                v-list-item-icon.mr-4
                  x-icon.blue--text.text--accent-4(size="1.5x")
                v-list-item-content
                  v-list-item-title.d-flex
                    span.mr-2.font-weight-regular.fs-14.colors--color16(
                      :v-tooltip="`[${item.code}] ${item.name}`"
                    )
                      | [{{item.code}}]
                    span.mr-2.text-truncate.font-weight-regular.fs-14.colors--color14 {{item.name}}
                    span.ml-auto.pl-3.font-weight-regular.fs-11.colors--color16(
                      :v-tooltip="`${getTickerPrice(item)}`"
                    )
                      | {{getTickerPrice(item)}}

          .d-flex.flex-grow-1.animated.fadeIn.no-results(
            v-if="!formData.tickers.length"
            style="height: 408px"
          )
            .d-flex.align-center.justify-center.flex-column.ma-auto
              v-icon.grey--text.fs-24 fas fa-robot
              .text-body.grey--text No selection
</template>

<script>
import CreatePortfolioSectionTemplate from "@/components/create-portfolio/create-portfolio-section-template";
import {
  SearchIcon,
  ChevronsRightIcon,
  ChevronsLeftIcon,
  PlusIcon,
  XIcon,
  AlertTriangleIcon,
} from "vue-feather-icons";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  // todo search filter yazılacak
  name: "CreatePortfolioSectionTickerSelection",

  components: {
    CreatePortfolioSectionTemplate,
    SearchIcon,
    ChevronsRightIcon,
    ChevronsLeftIcon,
    PlusIcon,
    XIcon,
    AlertTriangleIcon,
  },

  props: {
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    "formData.trainPeriod": {
      async handler() {
        await this.refresh();
      },
    },

    async selectedGroup() {
      await this.refresh();
    },

    tickerScrollPosition() {
      this.setLoading(true);
      this.$refs.tickerListRef.$el.scrollTop = this.tickerScrollPosition;
      this.setLoading(false);
    },

    search() {
      setTimeout(async () => await this.refresh(), 1200);
    },
  },

  data() {
    return {
      tickerScrollPosition: 0,
      tickerScrollHeight: 0,
    };
  },

  computed: {
    ...mapState("OptimizationConfig", ["formData", "selectedCurrencyType"]),

    ...mapState("Tickers", [
      "loading",
      "search",
      "page",
      "size",
      "allTickers",
      "filteredTickers",
      "selectedMarket",
      "selectedCategory",
      "categories",
      "selectedGroup",
      "groups",
      "currencyExchangeRates",
      "tickersMeta",
      "scrollRate",
    ]),

    selectedGroup_: {
      get() {
        return this.selectedGroup;
      },
      set(val) {
        this.setSelectedGroup(val);
      },
    },

    selectedCategory_: {
      get() {
        return this.selectedCategory;
      },
      set(val) {
        this.setSelectedCategory(val);
      },
    },

    search_: {
      get() {
        return this.search;
      },
      set(val) {
        this.setSearch(val);
      },
    },

    page_: {
      get() {
        return this.page;
      },
      set(val) {
        this.setPage(val);
      },
    },

    size_: {
      get() {
        return this.size;
      },
      set(val) {
        this.setSize(val);
      },
    },
  },

  methods: {
    ...mapMutations("Tickers", [
      "setSelectedGroup",
      "setSelectedCategory",
      "setSearch",
      "setLoading",
    ]),

    ...mapActions("Tickers", ["loadEntities", "loadNextPage"]),

    ...mapMutations("OptimizationConfig", ["setTickers"]),

    async refresh() {
      await this.loadEntities({
        group: this.selectedGroup || null,
        page: this.page_,
        size: this.size_,
        search: this.search_,
      });
    },

    async selectTicker(ticker) {
      const exists = this.formData.tickers.find(
        (item) => item.symbol === ticker.symbol
      );
      if (!exists) {
        this.setTickers([...this.formData.tickers, ticker]);
      }
    },

    getCurrencyRate(from, to) {
      const forwardRate = this.currencyExchangeRates.find(
        (rate) => rate.from === from && rate.to === to
      );
      const backwardRate = this.currencyExchangeRates.find(
        (rate) => rate.from === to && rate.to === from
      );
      if (forwardRate) {
        return forwardRate.rate;
      }
      if (backwardRate) {
        return 1 / backwardRate.rate;
      }
    },

    getTickerPrice(ticker) {
      // don't error if no price present
      if(!ticker.price) return "";
      if (this.selectedCurrencyType === ticker.currency) {
        return Number(ticker.price.toFixed(6));
      }
      const currencyRate = this.getCurrencyRate(
        ticker.currency,
        this.selectedCurrencyType
      );
      if (currencyRate) {
        return Number((ticker.price * currencyRate).toFixed(6));
      }

      // cross rate calculation with TRY if no direct rate found
      const from = this.getCurrencyRate(ticker.currency, "TRY");
      const to = this.getCurrencyRate(this.selectedCurrencyType, "TRY");
      if (from && to) {
        return Number((ticker.price * (from / to)).toFixed(6));
      }
      throw new Error(
        `No currency rate found for ${ticker.currency} to ${this.selectedCurrencyType}`
      );
    },

    async selectAllTickers() {
      await this.loadEntities({
        group: this.selectedGroup || null,
        search: this.search_,
      });
      this.setTickers([
        ...this.formData.tickers,
        ...this.filteredTickers.filter(
          (item) =>
            !this.formData.tickers.map((i) => i.symbol).includes(item.symbol)
        ),
      ]);
    },

    removeTicker(symbol) {
      const { weightConstraints, groupConstraints, views } =
        this.formData.constraints;
      if (weightConstraints.value.map((i) => i.symbol).includes(symbol)) {
        return this.$store.dispatch("toastr", {
          type: "error",
          message:
            "Please remove the " +
            this.parseSymbol(symbol).code +
            "'s weight constraint first " +
            "to be able to remove it from asset selection.",
        });
      }

      let inGroupConstraints = [];
      groupConstraints.value.forEach(({ group, symbols }) => {
        if (symbols.includes(symbol)) {
          inGroupConstraints.push(group);
        }
      });
      if (inGroupConstraints.length) {
        return this.$store.dispatch("toastr", {
          type: "error",
          message:
            this.parseSymbol(symbol).code +
            " is in use under group constraint(s): " +
            inGroupConstraints.join(", ") +
            ". " +
            "Please remove the group(s) to be able to remove asset selection.",
        });
      }

      let inViews = false;
      views.value.forEach(({ position, relative }) => {
        if (position === symbol) {
          inViews = true;
        }
        if (relative && relative === symbol) {
          inViews = true;
        }
      });
      if (inViews) {
        return this.$store.dispatch("toastr", {
          type: "error",
          message:
            this.parseSymbol(symbol).code +
            " has a view entry. Please remove that entry " +
            "first to be able to remove this asset from selection.",
        });
      }
      this.setTickers(
        this.formData.tickers.filter((item) => item.symbol !== symbol)
      );
    },

    removeAllTickers({ commit }) {
      this.formData.tickers.forEach((i) => this.removeTicker(i.symbol));
    },
  },

  updated() {
    const ref = this.$refs.tickerListRef;
    const el = ref?.$el;
    if (!el) return;

    el.addEventListener("scroll", async () => {
      if (this.filteredTickers.length >= this.tickersMeta.totalSize) return;
      this.tickerScrollHeight = Math.max(el.scrollHeight, el.clientHeight);

      if (el.scrollTop + el.clientHeight >= this.tickerScrollHeight) {
        await this.loadNextPage({
          trainPeriod: this.formData.trainPeriod,
          group: this.selectedGroup || null,
        });

        if (this.scrollRate !== 0) {
          this.tickerScrollPosition = this.tickerScrollHeight * this.scrollRate;
        }
      }
    });
  },

  async created() {
    await this.refresh();
  },
};
</script>

<style scoped lang="sass">
.v-list-item__icon i.v-icon
  margin-top: 2px

.portfolio-loading
  background: #1e487d80
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 999

::v-deep .no-results
  border-radius: 4px
  background: #E2E4E980

.risk-profile-select-chip
  margin-left: 8px
  color: var(--v-primary-base)
  transition: all .25s
  cursor: pointer
  padding: 4px 12px
  border-radius: 999px

  &.active
    color: #fff

  &.temkinli
    background: var(--v-success-darken1)

  &.dengeli
    background: var(--v-primary-base)

  &.atak
    background: var(--v-warning-darken1)

  &.agresif
    background: var(--v-error-darken1)

.risk-profile-view-chip
  font-size: 9px
  font-weight: bold
  color: #fff
  padding: 0 8px
  border-radius: 999px

  &.temkinli
    background: var(--v-success-darken1)

  &.dengeli
    background: var(--v-primary-base)

  &.atak
    background: var(--v-warning-darken1)

  &.agresif
    background: var(--v-error-darken1)

::v-deep .v-input__slot
  margin-bottom: 0 !important
  padding: .5em

::v-deep .v-input__control
  height: 2em !important

::v-deep .v-input--checkbox
  margin-top: 0 !important

.indexes
  border: 1px solid #ebe9f1
  border-radius: 6px
</style>
