import { render, staticRenderFns } from "./create-portfolio-section-ticker-selection.vue?vue&type=template&id=a2b2c76c&scoped=true&lang=pug&"
import script from "./create-portfolio-section-ticker-selection.vue?vue&type=script&lang=js&"
export * from "./create-portfolio-section-ticker-selection.vue?vue&type=script&lang=js&"
import style0 from "./create-portfolio-section-ticker-selection.vue?vue&type=style&index=0&id=a2b2c76c&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2b2c76c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCol,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VOverlay,VProgressCircular,VRow,VSubheader,VTextField,VVirtualScroll})
