import Vue from "vue";

export default {
  namespaced: true,
  state: () => ({
    loading: true,
    search: "",
    allTickers: [],
    selectedCategory: null,
    selectedGroup: "XK050",
    groups: [],
    categories: [],
    selectedMarket: "domesticMarkets",
    currencyExchangeRates: [],
    page: 1,
    size: 30,
    tickersMeta: null,
    scrollRate: 0,
    filteredTickers: [],
  }),
  mutations: {
    setLoading: (state, val) => (state.loading = val),
    setSearch: (state, val) => (state.search = val),
    setPage: (state, val) => (state.page = val),
    setSize: (state, val) => (state.size = val),
    setSelectedGroup: (state, val) => (state.selectedGroup = val),
    setSelectedCategory: (state, val) => (state.selectedCategory = val),
    setCategories: (state, val) => (state.categories = val),
    setTickers: (state, val) => (state.allTickers = val),
    setFilteredTickers: (state, val) => (state.filteredTickers = val),
    setGroups: (state, val) => (state.groups = val),
    setTickersMeta: (state, val) => (state.tickersMeta = val),
    setExchangeRates: (state, val) => (state.currencyExchangeRates = val),
    setScrollRate: (state, val) => (state.scrollRate = val),
  },

  actions: {
    async loadEntities({ commit }, { group, page, size, search }) {
      commit("setLoading", true);
      try {
        if (group === "all") {
          group = "";
        }
        const params = { group, page, size, search };
        const { data } = await Vue.$http.get("/data/entities", { params });
        const { data: exchangeData } = await Vue.$http.get("/data/currency-exchange-rate");


        commit("setExchangeRates", exchangeData);
        commit("setCategories", data.sectors);
        commit("setTickers", data.tickers);
        commit("setFilteredTickers", data.tickers);
        commit("setGroups", data.indexes);
        commit("setTickersMeta", data.tickerMeta);
      } catch (err) {
        console.error(err);
      }
      commit("setLoading", false);
    },

    async loadNextPage({ commit, state }, { trainPeriod, group }) {
      commit("setLoading", true);
      try {
        if (group === "all") {
          group = "";
        }
        const params = { trainPeriod, group, page: state.page + 1, size: state.size, search: state.search };
        const { data } = await Vue.$http.get("/data/entities", { params });
        const totalLength = state.allTickers.length + data.tickers.length;

        commit("setScrollRate", state.allTickers.length / totalLength);
        commit("setTickers", [...state.allTickers, ...data.tickers]);
        commit("setFilteredTickers", [...state.allTickers, ...data.tickers]);
        commit("setTickersMeta", data.tickerMeta);
      } catch (err) {
        console.error(err);
      }
      commit("setLoading", false);
    },

    async loadTickerDetails({ state }, symbols) {
      try {
        const { data } = await Vue.$http.get("/data/ticker-details", {
          params: {
            symbols,
            page: state.page,
            size: state.size,
            search: state.search,
          },
        });
        return data;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
