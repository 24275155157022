import Vue from "vue";
import UnauthorizedError from "../errors/unauthorized.js";
import { rollbar } from "../lib/rollbar.js";
import router from "../router";

const RISK_PROFILE_NAMES = {
  conservative: { TR: "Temkinli", EN: "Conservative" },
  moderate: { TR: "Dengeli", EN: "Moderate" },
  growth: { TR: "Atak", EN: "Growth" },
  aggressive: { TR: "Agresif", EN: "Aggressive" },
};

const RISK_PROFILE_VALUES = {
  conservative: 1,
  moderate: 1,
  growth: 1,
  aggressive: 1,
};

const INVESTOR_TYPE_NAMES = {
  general: { TR: "Genel", EN: "General" },
  qualified: { TR: "Nitelikli", EN: "Qualified" },
};

const ENTITY_TYPE_NAMES = {
  real: { TR: "Gerçek", EN: "Real" },
  corporate: { TR: "Kurumsal", EN: "Corporate" },
};

const CURRENCY_TYPE_NAMES = ["TRY", "USD", "EUR", "XAU"];

const DEFAULT_FORM_DATA = {
  cardinalityCanBeSet: false,
  optimization: null,
  pendingOptimization: null,
  investorType: null, // NEED TO BE SET BEFORE INITIALIZED
  trainPeriod: 365,
  target: 0,
  objectives: [],
  tickers: [],
  entityType: null,
  currencyType: null,
  riskProfile: {
    name: null, // NEED TO BE SET BEFORE INITIALIZED
    value: 0, // NEED TO BE SET BEFORE INITIALIZED
  },
  constraints: {
    cardinality: {
      enabled: false,
      value: {
        min: null,
        max: null,
      },
    },
    noShort: true,
    short: false,
    weight: false,
    maxShort: 0,
    views: {
      enabled: true,
      value: [],
    },
    groupConstraints: {
      enabled: false,
      value: [],
    },
    weightConstraints: {
      enabled: false,
      value: [],
    },
  },
};

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    saving: false,
    reloading: false,

    allConfigs: [],
    selectedInvestorType: null,
    selectedRiskProfile: null,
    selectedEntityType: null,
    selectedCurrencyType: null,

    formData: null,

    RISK_PROFILE_NAMES,
  }),

  getters: {
    activeConstraint(state) {
      const constraints = state.formData.constraints;
      const all = ["noShort", "short", "weight"];
      let selected = null;
      for (let i = 0; i < all.length; i++) {
        if (constraints[all[i]]) {
          selected = all[i];
        }
      }
      return selected;
    },
  },

  mutations: {
    setLoading: (state, val) => (state.loading = val),
    setSaving: (state, val) => (state.saving = val),
    setReloading: (state, val) => (state.reloading = val),
    setAllConfigs: (state, val) => {
      state.allConfigs = val;
      setFormDataState(state);
    },
    setSelectedInvestorType(state, payload) {
      state.selectedInvestorType = payload;
      setFormDataState(state);
    },
    setSelectedRiskProfile(state, payload) {
      state.selectedRiskProfile = payload;
      setFormDataState(state);
    },
    setSelectedEntityType(state, payload) {
      state.selectedEntityType = payload;
      setFormDataState(state);
    },
    setSelectedCurrencyType(state, payload) {
      state.selectedCurrencyType = payload;
      setFormDataState(state);
    },
    setTickers: (state, val) => (state.formData.tickers = val),
    setTrainPeriod: (state, val) => (state.formData.trainPeriod = val),
    setTarget: (state, val) => (state.formData.target = val),
    setRiskProfileValue: (state, val) =>
      (state.formData.riskProfile.value = val),
    setObjective: (state, val) => (state.formData.objectives = [val]),
    setViewsEnabled: (state, val) =>
      (state.formData.constraints.views.enabled = val),
    addViewItem: (state, val) =>
      state.formData.constraints.views.value.push(val),
    removeViewItem: (state, index) =>
      state.formData.constraints.views.value.splice(index, 1),
    removeAllViewItem: (state) => (state.formData.constraints.views.value = []),
    setEnabledConstraint: (state, constraint) => {
      ["noShort", "short", "weight"].forEach((name) => {
        state.formData.constraints[name] = name === constraint;
      });
      if (constraint !== "weight") {
        state.formData.constraints.groupConstraints.enabled = false;
        state.formData.constraints.weightConstraints.enabled = false;
      }
    },
    enableGroupConstraint: (state) =>
      (state.formData.constraints.groupConstraints.enabled = true),
    enableWeightConstraint: (state) =>
      (state.formData.constraints.weightConstraints.enabled = true),
    disableGroupConstraint: (state) =>
      (state.formData.constraints.groupConstraints.enabled = false),
    disableWeightConstraint: (state) =>
      (state.formData.constraints.weightConstraints.enabled = false),
    addGroupItem: (state, val) =>
      state.formData.constraints.groupConstraints.value.push(val),
    removeGroupItem: (state, index) =>
      state.formData.constraints.groupConstraints.value.splice(index, 1),
    addWeightItem: (state, val) =>
      state.formData.constraints.weightConstraints.value.push(val),
    removeWeightItem: (state, index) =>
      state.formData.constraints.weightConstraints.value.splice(index, 1),
    setMaxShort: (state, val) => (state.formData.constraints.maxShort = val),
    enableCardinality: (state, val) =>
      (state.formData.constraints.cardinality.enabled = true),
    disableCardinality: (state, val) =>
      (state.formData.constraints.cardinality.enabled = false),
    setCardinalityMin: (state, val) =>
      (state.formData.constraints.cardinality.value.min = val),
    setCardinalityMax: (state, val) =>
      (state.formData.constraints.cardinality.value.max = val),
    forbidCardinalityEntry: (state) =>
      (state.formData.cardinalityCanBeSet = false),
    /**
     * Sets the pendingOptimization property of given config object
     * @param {Object} state                       State object
     * @param {String} options.investorType        required to determine the correct config object
     * @param {String} options.riskProfileName     required to determine the correct config object
     * @param {Object} options.pendingOptimization Optimization object
     * @param {Object} options.entityType required to determine the correct config object
     * @param {Object} options.currencyType required to determine the correct config object
     */
    setPendingOptimizationOf(
      state,
      {
        investorType,
        riskProfileName,
        pendingOptimization,
        entityType,
        currencyType,
      }
    ) {
      const config = state.allConfigs.find(
        (item) =>
          item.investorType === investorType &&
          item.riskProfile.name === riskProfileName &&
          item.entityType === entityType &&
          item.currencyType === currencyType
      );
      if (config) {
        config.pendingOptimization = pendingOptimization;
        if (
          pendingOptimization &&
          pendingOptimization.status === "finished" &&
          pendingOptimization?.results?.[0]?.cardinality
        ) {
          config.cardinalityCanBeSet = true;
          config.constraints.cardinality.value.min = null;
          config.constraints.cardinality.value.max = null;
        }
      }
    },

    setAllocationOf(
      state,
      { investorType, riskProfileName, entityType, currencyType, allocation }
    ) {
      const config = state.allConfigs.find(
        (item) =>
          item.investorType === investorType &&
          item.riskProfile.name === riskProfileName &&
          item.entityType === entityType &&
          item.currencyType === currencyType
      );
      if (config) {
        const pendingOptimization = config.pendingOptimization;
        if (
          pendingOptimization &&
          pendingOptimization.status === "finished" &&
          pendingOptimization?.results?.[0]?.allocation
        ) {
          pendingOptimization.results[0].allocation = allocation;
        }
      }
    },
    approveOptimizationOf(
      state,
      { investorType, riskProfileName, entityType, currencyType }
    ) {
      const config = state.allConfigs.find(
        (item) =>
          item.investorType === investorType &&
          item.riskProfile.name === riskProfileName &&
          item.entityType === entityType &&
          item.currencyType === currencyType
      );
      if (config) {
        config.optimization = config.pendingOptimization;
        config.pendingOptimization = null;
      }
    },
  },
  actions: {
    async init({ commit, rootState }) {
      commit("setLoading", true);
      let allConfigs = [];
      const params = {
        page: rootState.Tickers.page,
        size: rootState.Tickers.size,
        search: rootState.Tickers.search,
      };

      try {
        const { data } = await Vue.$http.get("/optimization-config", {
          params,
        });
        allConfigs = data;
      } catch (error) {
        console.error(error);
        if (!(error instanceof UnauthorizedError)) {
          alert(getErrorMessage(error));
        }
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("activated");
        router.push("/login");
        throw error;
      }
      const existingKeys = allConfigs.map((config) => {
        return [
          config.investorType,
          config.riskProfile.name,
          config.entityType,
          config.currencyType,
        ].join("-");
      });
      // Create the defaults
      const combinations = cartesian(
        Object.keys(INVESTOR_TYPE_NAMES),
        Object.keys(RISK_PROFILE_NAMES),
        Object.keys(ENTITY_TYPE_NAMES),
        CURRENCY_TYPE_NAMES
      ).filter((item) => !existingKeys.includes(item.join("-")));

      const allNewConfigs = combinations.map(
        ([investorType, riskProfile, entityType, currencyType]) => {
          const obj = clone(DEFAULT_FORM_DATA);
          obj.investorType = investorType;
          obj.riskProfile.name = riskProfile;
          obj.riskProfile.value = RISK_PROFILE_VALUES[riskProfile];
          obj.entityType = entityType;
          obj.currencyType = currencyType;
          return obj;
        }
      );

      allConfigs = [...allConfigs, ...allNewConfigs];

      for (let i = 0; i < allConfigs.length; i++) {
        const config = allConfigs[i];
        // cardinality availability
        config.cardinalityCanBeSet = false;

        // weight constraint status
        if (
          config.constraints.groupConstraints.enabled ||
          config.constraints.weightConstraints.enabled
        ) {
          config.constraints.weight = true;
        } else {
          config.constraints.weight = false;
        }
      }

      commit("setAllConfigs", allConfigs);
      commit("setSelectedInvestorType", allConfigs[0].investorType);
      commit("setSelectedRiskProfile", allConfigs[0].riskProfile.name);
      commit("setSelectedEntityType", allConfigs[0].entityType);
      commit("setSelectedCurrencyType", allConfigs[0].currencyType);
      commit("setLoading", false);

      return allConfigs;
    },

    async reload({ commit, state, rootState }) {
      let reloadRequired = [];

      for (let i = 0; i < state.allConfigs.length; i++) {
        const {
          investorType,
          riskProfile: { name: riskProfileName },
          entityType,
          currencyType,
          pendingOptimization,
        } = state.allConfigs[i];

        if (pendingOptimization && pendingOptimization.status !== "finished") {
          reloadRequired.push(
            investorType +
              "-" +
              riskProfileName +
              "-" +
              entityType +
              "-" +
              currencyType
          );
        }
      }

      if (!reloadRequired.length || state.reloading) {
        return;
      }
      commit("setReloading", true);

      try {
        const params = {
          page: rootState.Tickers.page,
          size: rootState.Tickers.size,
          search: rootState.Tickers.search,
        };
        const { data } = await Vue.$http.get("/optimization-config", {
          params,
        });

        for (let i = 0; i < data.length; i++) {
          const {
            investorType,
            riskProfile: { name: riskProfileName },
            entityType,
            currencyType,
            pendingOptimization,
          } = data[i];

          if (
            reloadRequired.includes(
              investorType +
                "-" +
                riskProfileName +
                "-" +
                entityType +
                "-" +
                currencyType
            )
          ) {
            if (
              pendingOptimization &&
              pendingOptimization.status === "finished"
            ) {
              commit("setPendingOptimizationOf", {
                investorType,
                riskProfileName,
                entityType,
                currencyType,
                pendingOptimization,
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
        if (!(error instanceof UnauthorizedError)) {
          alert(getErrorMessage(error));
        }
        throw error;
      }
      commit("setReloading", false);
    },

    async save({ commit, state }) {
      commit("setSaving", true);
      // await new Promise(res => setTimeout(() => res(), 2000));
      try {
        const { data } = await Vue.$http.post(
          "/optimization-config/save-all",
          state.allConfigs
        );
      } catch (err) {
        console.error(err.stack);
        if (!(err instanceof UnauthorizedError)) {
          alert(getErrorMessage(err));
        }
        rollbar.error(err);
      }
      commit("setSaving", false);
    },

    async calculate({ commit, state }) {
      const conf = {
        investorType: state.formData.investorType,
        riskProfileName: state.formData.riskProfile.name,
        entityType: state.formData.entityType,
        currencyType: state.formData.currencyType,
      };
      try {
        const { data } = await Vue.$http.post(
          "/optimization-config/calculate",
          conf
        );
        commit("setPendingOptimizationOf", {
          ...conf,
          pendingOptimization: data.pendingOptimization,
        });
      } catch (err) {
        console.error(err);
        if (!(err instanceof UnauthorizedError)) {
          alert(getErrorMessage(err));
        }
        rollbar.error(err);
      }
    },

    async approve({ commit, state }) {
      try {
        const conf = {
          investorType: state.formData.investorType,
          riskProfileName: state.formData.riskProfile.name,
          entityType: state.formData.entityType,
          currencyType: state.formData.currencyType,
        };
        const { data } = await Vue.$http.put(
          "/optimization-config/approve-pending-optimization",
          conf
        );
        commit("approveOptimizationOf", conf);
      } catch (err) {
        console.error(err);
        if (!(err instanceof UnauthorizedError)) {
          alert(getErrorMessage(err));
        }
        rollbar.error(err);
      }
    },

    async remove({ commit, state }) {
      try {
        const params = {
          investorType: state.formData.investorType,
          riskProfileName: state.formData.riskProfile.name,
          entityType: state.formData.entityType,
          currencyType: state.formData.currencyType,
          pendingOptimization: null,
        };
        await Vue.$http.delete("/optimization-config/pending-optimization", {
          params,
        });
        commit("setPendingOptimizationOf", params);
      } catch (err) {
        console.error(err);
        if (!(err instanceof UnauthorizedError)) {
          alert(getErrorMessage(err));
        }
        rollbar.error(err);
      }
    },

    async updateAllocation({ commit, state, dispatch }, allocation) {
      try {
        const data = {
          investorType: state.formData.investorType,
          riskProfileName: state.formData.riskProfile.name,
          entityType: state.formData.entityType,
          currencyType: state.formData.currencyType,
          allocation: allocation.map((i) => ({
            symbol: i.symbol,
            value: i.value / 100,
          })),
        };
        await Vue.$http.put("/optimization-config/allocation", data);
        commit("setAllocationOf", data);
      } catch (err) {
        console.error(err);
        if (!(err instanceof UnauthorizedError)) {
          alert(getErrorMessage(err));
        }
        rollbar.error(err);
      }
    },
    /**
     * @param source {investorType, riskProfile, entityType, currencyType}
     * @param target {investorType, riskProfile, entityType, currencyType}
     */
    transferData({ commit, state, dispatch }, { source, target }) {
      let sourceConfig = clone(
        state.allConfigs.find((config) => {
          return (
            config.investorType === source.investorType &&
            config.riskProfile.name === source.riskProfile &&
            config.entityType === source.entityType &&
            config.currencyType === source.currencyType
          );
        })
      );

      const newAllConfigs = state.allConfigs.map((config) => {
        if (
          config.investorType === target.investorType &&
          config.riskProfile.name === target.riskProfile &&
          config.entityType === target.entityType &&
          config.currencyType === target.currencyType
        ) {
          sourceConfig.investorType = config.investorType;
          sourceConfig.riskProfile.name = config.riskProfile.name;
          sourceConfig.entityType = config.entityType;
          sourceConfig.currencyType = config.currencyType;

          if (sourceConfig.optimization) {
            sourceConfig.optimization.investorType = config.investorType;
            sourceConfig.optimization.entityType = config.entityType;
            sourceConfig.optimization.currencyType = config.currencyType;
            sourceConfig.optimization.configuration.riskProfile.name =
              config.riskProfile.name;
            delete sourceConfig.optimization._id;
            delete sourceConfig.optimization.id;
            sourceConfig.optimization.reCreate = true;
          }
          if (sourceConfig.pendingOptimization) {
            sourceConfig.pendingOptimization.investorType = config.investorType;
            sourceConfig.pendingOptimization.entityType = config.entityType;
            sourceConfig.pendingOptimization.currencyType = config.currencyType;
            sourceConfig.pendingOptimization.configuration.riskProfile.name =
              config.riskProfile.name;
            delete sourceConfig.pendingOptimization._id;
            delete sourceConfig.pendingOptimization.id;
            sourceConfig.pendingOptimization.reCreate = true;
          }

          // Do not transfer optimization goals
          sourceConfig.objectives = clone(config.objectives);
          sourceConfig.target = config.target;
          sourceConfig.riskProfile.value = config.riskProfile.value;

          return sourceConfig;
        } else return config;
      });
      commit("setAllConfigs", newAllConfigs);
      dispatch("save");
    },
  },
};

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const validationRegex = /^ValidationError: .+?: (?<message>.+?)$/;
// Not ideal, but extract validation errors from the error message if possible
function getErrorMessage(err) {
  const message = err?.response?.data?.message?.match(validationRegex)?.groups?.message;
  if (message) return message;
  return "An unknown error occured. Please check console and report the issue to Magnus";
}

function cartesian(...a) {
  return a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())));
}

function setFormDataState(state) {
  if (
    state.selectedInvestorType &&
    state.selectedRiskProfile &&
    state.selectedEntityType &&
    state.selectedCurrencyType
  ) {
    state.formData = state.allConfigs.find(
      (item) =>
        item.investorType === state.selectedInvestorType &&
        item.riskProfile.name === state.selectedRiskProfile &&
        item.entityType === state.selectedEntityType &&
        item.currencyType === state.selectedCurrencyType
    );

    if (state.formData) {
      state.formData.cardinalityCanBeSet = false;
    }
  }
}
